import { TextField } from "@rmwc/textfield";
import { graphql, navigate } from "gatsby";
import React from "react";
import { HeroLayout } from "../components/hero-layout";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { LandingApiClient } from "../imports/landing/types/LandingApiClient";
import { rhythm } from "../utils/typography";
import { Button } from "@rmwc/button";
import { captureException } from "../components/sentry";

class GetStarted extends React.Component {
  state = {
    email: "",
    error: null,
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const api = new LandingApiClient(`${process.env.API_URL}/api`);

    api
      .sendVerificationEmail({ email: this.state.email })
      .then((res) => {
        if (res.status === "success") {
          return navigate("/verify-email/", {
            state: {
              email: this.state.email,
            },
          });
        } else if (res.status === "invalid-email") {
          this.setState({
            error:
              "This mailbox doesn't exist, please check that you entered email correctly",
          });
        } else if (res.status === "free-email") {
          this.setState({
            error:
              "Please provide your <b>company email</b>, as Remeet is a productivity tool for teams.",
          });
        } else {
          this.setState({ error: "Unexpected error. Let's try again?" });
          captureException(
            new Error("Unexpected get status response: " + res.status)
          );
        }

        // TODO(@anton): Add error handling
      })
      .catch((e) => {
        captureException(e);
        this.setState({ error: "Unexpected error. Let's try again?" });
        // TODO(@anton): Add error handling
      });
  };

  render() {
    return (
      <Layout withMaxWidth={false}>
        <SEO />
        <HeroLayout>
          <h1 className="title" style={{ marginBottom: rhythm(1 / 2) }}>
            Let’s verify your email
          </h1>

          <h2
            className="subtitle"
            style={{ marginTop: 0, marginBottom: rhythm(1) }}
          >
            Please provide your work email address so that we can setup calendar
            integration
          </h2>
          <div className={`sign-up-form sign-up-form--align-left`}>
            <form
              onSubmit={this.handleSubmit}
              style={{ marginBottom: rhythm(1 / 2) }}
            >
              <div className="email-field">
                <TextField
                  outlined
                  name="email"
                  required
                  autoComplete="email"
                  label={"Company email"}
                  type="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
                <Button type="submit" unelevated>
                  Verify email
                </Button>
              </div>
            </form>
            {/*// TODO: A11Y*/}
            {this.state.error && (
              <p className="mdc-typography--body2">
                <span
                  className="error"
                  dangerouslySetInnerHTML={{ __html: this.state.error }}
                ></span>
              </p>
            )}
          </div>
        </HeroLayout>
      </Layout>
    );
  }
}

export default GetStarted;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
